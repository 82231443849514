<template>
    <div class="row mb-3" v-for="(item, index) in DanhSachHangHoa" :key="index">
        <div class="xs12 card-ticket">
            <div
                class="trang-thai"
                :style="`color: ${item.maMauThanhToan}; border-color: ${item.maMauThanhToan}`"
                v-if="item.trangThaiThanhToan"
            >
                {{ item.trangThaiThanhToan }}
            </div>

            <div
                class="row pa-3 pb-2 align-center"
                style="position: relative; z-index: 1"
            >
                <div class="frame-img row mr-2 align-center">
                    <img
                        v-if="dsAnhLoad[index]"
                        :src="dsAnhLoad[index]"
                        alt=""
                        @click="XemAnh(dsAnhLoad[index])"
                    />
                    <img v-else src="../../../../public/logo.png" alt="" />
                </div>
                <div class="grow" @click="XemChiTiet(item)">
                    <div :class="rowText">
                        <div class="mr-2">Trả tại:</div>
                        <div :class="textRight">
                            {{ item.tenDiemNhan }}
                        </div>
                    </div>
                    <div :class="rowText">
                        <div class="mr-2">Cước:</div>
                        <div :class="textRight" class="color-warning">
                            {{ $MoneyFormat(item.donGia) }}đ
                        </div>
                    </div>
                    <div :class="rowText">
                        <div class="mr-2">Người nhận:</div>
                        <div
                            :class="textRight"
                            @click.prevent.stop="
                                w.location.href = `tel:${item.soDienThoaiNguoiNhan}`
                            "
                        >
                            <a class="color-primary" style="text-decoration: underline">
                                {{ $Helper.formatPhone(item.soDienThoaiNguoiNhan) }}</a
                            >
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="xs12 font-medium"
                            :style="`color: ${item.maMauVanChuyen}`"
                        >
                            {{ item.trangThaiVanChuyen }}
                            <span
                                class="font-italic font-regular font-12"
                                v-if="
                                    !item.thoiGianGiaoHangThucTe &&
                                    item.thoiGianDuKienGiaoHang
                                "
                            >
                                (Dự kiến:
                                {{
                                    $Helper.formatDateTime(item.thoiGianDuKienGiaoHang)
                                }})</span
                            >
                            <span
                                v-else-if="item.thoiGianGiaoHangThucTe"
                                class="font-italic font-regular font-12"
                            >
                                ({{
                                    $Helper.formatDateTime(item.thoiGianGiaoHangThucTe)
                                }})
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
                <div class="xs6 text-xs-center" style="border-right: 1px solid #dadce0">
                    <DxButton
                        text="In phiếu"
                        type="default"
                        styling-mode="text"
                        width="100%"
                        @click="layThongTinVe(item.idNhatKy)"
                    />
                </div>
                <div class="xs6 text-xs-center">
                    <DxButton
                        text="Trả hàng"
                        type="default"
                        styling-mode="text"
                        width="100%"
                        :disabled="!!item.thoiGianGiaoHangThucTe"
                        @click="TraHang(item)"
                    />
                </div>
            </div>
        </div>
    </div>
    <ViewImageVue v-model:statePopup="statePopup" :link="link" />
</template>

<script>
let w = window;
import { DxButton } from "devextreme-vue";
// import ChuyenDi from "../../../../class/ChuyenDi";
// import DonHang from "../../../../class/DonHang";
import { mapActions, mapGetters } from "vuex";
import ViewImageVue from "../../../components/_Common/ViewImage.vue";
export default {
    components: {
        DxButton,
        ViewImageVue,
    },
    props: {
        DanhSachHangHoa: { type: Array, default: () => [] },
    },
    data() {
        return {
            w,
            rowText: "row mb-1 align-center",
            textRight: " font-medium",
            LinkGetFile: SP["API_HangHoa"],
            ErrorAnh: false,
            link: "",
            statePopup: false,
            dsAnhLoad: [],
        };
    },
    computed: {
        ...mapGetters("Ve", ["chuyenDiGanDay"]),
    },
    watch: {
        DanhSachHangHoa: {
            handler: function (val) {
                if (val.length > 0) {
                    this.getAnh();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        ...mapActions("HangHoa", ["GetFileImg"]),
        async getAnh() {
            this.dsAnhLoad = [];
            try {
                for (let index = 0; index < this.DanhSachHangHoa.length; index++) {
                    const e = this.DanhSachHangHoa[index];
                    if (e.danhSachHinhAnh.length > 0) {
                        let rs = await this.$Core.Api.HangHoa(
                            this.$t("urlFile.GetFileImg"),
                            {
                                idFile: e.danhSachHinhAnh[0],
                            },
                        )
                            .Config((c) => {
                                c.responseType = "blob";
                                c.validateStatus = () => true;
                            })
                            .Get();
                        let link = URL.createObjectURL(rs.Data);
                        if (rs.StatusCode == 200) {
                            this.dsAnhLoad[index] = link;
                        } else {
                            this.dsAnhLoad[index] = null;
                        }
                    } else {
                        this.dsAnhLoad[index] = null;
                    }
                }
            } catch (error) {
                return null;
            }
        },
        async TraHang(item) {
            this.$emit("TraHang", item);
        },
        XemChiTiet(item) {
            let query = { ...item };
            this.$router.push({
                path: "/Xem-Chi-Tiet-Hang-Hoa",
                query: query,
            });
        },
        async layThongTinVe(idNhatKy) {
            let toast = {
                type: "error",
                visible: true,
            };
            try {
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVeTheoIdNhatKyGuiHang"),
                    "IdNhatKy",
                    idNhatKy,
                );
                if (rs == "error") {
                    toast.message = "In thất bại!";
                    this.emitter.emit("onToast", toast);
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                console.error(error);
            }
        },
        onErrorAnh() {
            this.ErrorAnh = true;
        },
        XemAnh(item) {
            this.link = item;
            this.statePopup = true;
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.frame-img {
    width: 80px;
    height: 80px;
    text-align: center;
    overflow: hidden;
}
.frame-img img {
    width: 100%;
    height: auto;
}
.trang-thai {
    position: absolute;
    top: 16px;
    right: -48px;
    transform: rotate(45deg);
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 4px 0;
    width: 144px;
    text-align: center;
    z-index: 2;
    opacity: 0.4;
}
</style>
